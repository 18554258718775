* {
	--main-color: 0deg, 100%, 70%;
	--main-color-hsl: hsl(var(--main-color));
	
	--header-text-color: 0deg, 0%, 90%;
	--header-text-color-hsl: hsl(var(--header-text-color));

	--paragraph-text-color: 0deg, 0%, 70%;
	--paragraph-text-color-hsl: hsl(var(--paragraph-text-color));

	--shadow-color: #000;

	--card-color-hsl: hsl(0deg, 0%, 20%);
	--card-header-hsl: hsl(0deg, 0%, 100%);
	--card-paragraph-hsl: hsl(0deg, 0%, 70%);

	margin: 0;
	padding: 0;
	box-sizing: border-box;
	line-height: 1;
	font-family: 'Open Sans', sans-serif;
	color: var(--paragraph-text-color-hsl);
}

html {
	background-color: hsl(0deg, 0%, 10%);
}
#root {
	margin-bottom: 72px;
	padding-top: env(safe-area-inset-top);
}

main {
	margin: 16px;
}

h1 {
	font-family: 'Pacifico', cursive;
	text-align: center;
	margin: 32px 0 16px 0;
	font-size: 32px;
	font-weight: normal;
	color: var(--header-text-color-hsl);
}
h2 {
	font-family: 'Pacifico', cursive;
	text-align: center;
	margin: 32px 0 16px 0;
	font-size: 24px;
	font-weight: normal;
	color: var(--header-text-color-hsl);
}
h3 {
	font-family: 'Pacifico', cursive;
	text-align: center;
	margin: 32px 32px 16px 32px;
	font-size: 20px;
	font-weight: normal;
	color: var(--header-text-color-hsl);
}
h4 {
	text-align: center;
	margin: 32px 32px 16px 32px;
	font-size: 20px;
	font-weight: bold;
	color: var(--header-text-color-hsl);
}

p {
	max-width: 512px;
	margin: 16px auto;
	text-align: justify;
	line-height: normal;
	hyphens: auto;
}

main ul {
	max-width: 512px;
	margin: 16px auto;
	list-style: none;
	list-style-position: inside;
	list-style-image: url(../public/point.svg);
}

svg {
	vertical-align: bottom;
	overflow: visible;
}
img {
	vertical-align: bottom;
}

#profile-picture {
	position: relative;
	width: 256px;
	height: 256px;
	margin: 32px auto;
	padding: 24px;
	border-radius: 50%;
	box-shadow: inset 4px 4px 16px -4px var(--shadow-color);
	background-color: var(--main-color-hsl);
}
#profile-picture img {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	box-shadow: 2px 2px 8px -2px var(--shadow-color);
}
.links {
	margin: 16px;
	text-align: center;
}
.links a {
	display: inline-block;
}

.grid {
	display: grid;
	margin: 32px 0;
	gap: 16px;
	grid-template-columns: repeat(auto-fit, minmax(128px, 1fr));
}
.grid .card .picture {
	position: relative;
	margin: 0 auto;
	max-width: 512px;
	border-radius: 6px;
	box-shadow: inset 2px 2px 8px -2px var(--shadow-color);
	background-color: hsl(0deg, 0%, 20%);
	aspect-ratio: 1 / 1;
}
.grid .card .picture img {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - 8px);
	border-radius: 4px;
	box-shadow: 1px 1px 4px -1px var(--shadow-color);
}
.grid .card.tall .picture img {
	width: calc(56.25% - 4.5px);
}
.grid .card h3 {
	color: var(--card-header-hsl);
	margin: 16px;
}
.grid .card h4 {
	color: var(--card-paragraph-hsl);
	margin: 16px;
}
.grid .card h4 svg {
	margin-left: -4px;
	height: 20px;
}
.grid .card p {
	color: var(--card-paragraph-hsl);
}
.grid .card p:last-of-type {
	text-align: center;
}
.grid .card a {
	display: block;
	margin: 16px;
	text-align: center;
	color: var(--main-color-hsl);
	word-break: break-word;
}

#menu {
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 16px 0;

	background-color: var(--main-color-hsl);
	border-radius: 24px 24px 0 0;
}
#menu ul {
	display: grid;
	grid-auto-flow: column;

	list-style: none;

	font-size: 24px;
	text-align: center;
}
#menu ul li a {
	-webkit-tap-highlight-color: transparent;
}
#menu ul li svg * {
	transition: .2s ease-in-out;
}

@media screen and (min-width: 512px) {
	.grid {
		grid-template-columns: repeat(auto-fit, minmax(192px, 1fr));
	}
}
@media screen and (min-aspect-ratio: 1/1) {
	#root {
		display: grid;
		grid-template-rows: 1fr max-content;
		height: 100vh;

		margin-left: 56px;
		margin-bottom: 0;
	}

	#menu {
		top: 0;
		left: 0;
		width: auto;
		padding: 16px 16px 16px calc(16px + env(safe-area-inset-left));
		border-radius: 0;
	}

	#menu ul {
		grid-auto-flow: row;
		gap: 16px;
		justify-items: center;
		height: 100%;
		width: 24px;
		align-content: center;
		justify-content: center;
	}

	main {
		padding-left: env(safe-area-inset-left);
	}
}